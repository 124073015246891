import { AppComponent } from './app.component';
import { BatchListComponent } from './batch-list/batch-list.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { TopBarComponent } from './top-bar/top-bar.component';
import { AreaItemComponent } from './overview/area-item/area-item.component';
import { BatchItemComponent } from './overview/batch-item/batch-item.component';
import { BatchEditorComponent } from './batch/batch-editor/batch-editor.component';
import { BatchCreateComponent } from './batch/batch-create/batch-create.component';
import { BatchUpdateComponent } from './batch/batch-update/batch-update.component';
import { BatchViewComponent } from './batch/batch-view/batch-view.component';
import { DeviceEditorComponent } from './device/device-editor/device-editor.component';
import { DeviceUpdateComponent } from './device/device-update/device-update.component';
import { OverviewComponent } from './overview/overview.component';
import { BatchReportComponent } from './batch/batch-report/batch-report.component';
import { ExportToPdfComponent } from './export-to-pdf/export-to-pdf.component';
import { ReportChartComponent } from './batch/batch-report/report-chart/report-chart.component';
import { CardComponent } from './elements/card/card.component';
import { InlineTitleComponent } from './elements/inline-title/inline-title.component';
import { registerLocaleData } from '@angular/common';
import localeEn150 from '@angular/common/locales/en-150';
import { LoadingComponent } from './elements/loading/loading.component';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { UtcToLocalPipe } from './utils/utc-to-local.pipe';
import { TagNamePipe } from './utils/tag-name.pipe';
import { BarcodeScannerComponent } from './batch/batch-editor/barcode-scanner/barcode-scanner.component';
import { BatchIdToNamePipe } from './utils/batch-id-to-name.pipe';
import { SettingsComponent } from './settings/settings.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TemperaturePipe } from './utils/temperature-pipe';
import { ActualsModalComponent } from './batch/batch-view/actuals-modal/actuals-modal.component';
import { TemperatureInputPipe } from './utils/temperature-input.pipe';
import { TemperatureOutputPipe } from './utils/temperature-output.pipe';
import { BatchIdToItemPipe } from './utils/batch-id-to-item.pipe';
import { PaintComponentPipe } from './utils/paint-component.pipe';
import { DistanceInputPipe } from './utils/distance-input.pipe';
import { DistanceOutputPipe } from './utils/distance-output.pipe';
import { IntervalFromMinutesPipe } from './utils/interval-from-minutes.pipe';
import { IntervalFromTimeSpanpipe } from './utils/Interval-from-TimeSpan-pipe';
import { CustomFieldsComponent } from './elements/custom-fields/custom-fields.component';
import { CustomFieldsTabComponent } from './elements/custom-fields-tab/custom-fields-tab.component';
import { TemplatesComponent } from './elements/templates/templates.component';
import { SelectCustomFieldsComponent } from './elements/select-custom-fields/select-custom-fields.component';
import { PostApplicationModalComponent } from './batch/batch-view/post-application-modal/post-application-modal.component';
import { CustomFieldsRowComponent } from './batch/batch-editor/customfields-row/custom-fields-row.component';
import { BatchViewPhotoevidenceComponent } from './batch/batch-view/batch-view-photoevidence/batch-view-photoevidence.component';
import { DashboardComponent } from './facilitymonitoring/Dashboard/dashboard.component';
import { ChartComponent } from './facilitymonitoring/Dashboard/Charts/dashboard-chart.component';
import { MaxMinDeviceEditorComponent } from './device/device-editor/Components/device-maxmin.component';
export const protectedResourceMap = [
    [environment.API_BASE_PATH, [environment.SCOPE]]
];
export function HttpLoaderFactory(http) {
    return new TranslateHttpLoader(http, environment.TRANSLATION_LOCATION, '.json');
}
registerLocaleData(localeEn150);
const components = [
    AppComponent,
    TopBarComponent,
    BatchListComponent,
    PageNotFoundComponent,
    AreaItemComponent,
    BatchItemComponent,
    BatchEditorComponent,
    BatchCreateComponent,
    BatchUpdateComponent,
    BatchViewComponent,
    DeviceEditorComponent,
    DeviceUpdateComponent,
    BatchViewPhotoevidenceComponent,
    OverviewComponent,
    BatchReportComponent,
    ExportToPdfComponent,
    ReportChartComponent,
    CardComponent,
    InlineTitleComponent,
    LoadingComponent,
    UtcToLocalPipe,
    TemperaturePipe,
    TagNamePipe,
    BarcodeScannerComponent,
    BatchIdToNamePipe,
    ActualsModalComponent,
    TemperatureInputPipe,
    TemperatureOutputPipe,
    DistanceInputPipe,
    DistanceOutputPipe,
    BatchIdToItemPipe,
    IntervalFromMinutesPipe,
    IntervalFromTimeSpanpipe,
    SettingsComponent,
    CustomFieldsComponent,
    CustomFieldsTabComponent,
    CustomFieldsRowComponent,
    TemplatesComponent,
    SelectCustomFieldsComponent,
    PostApplicationModalComponent,
    PaintComponentPipe,
    MaxMinDeviceEditorComponent,
    DashboardComponent,
    ChartComponent
];
const ɵ0 = environment.API_BASE_PATH, ɵ1 = environment.BATCH_MAPPING_API_URL;
export class AppModule {
}
export { ɵ0, ɵ1 };
