import * as tslib_1 from "tslib";
import { OnInit, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import * as HighchartsStock from 'highcharts/highstock';
import exporting from 'highcharts/modules/exporting';
import CSVexport from 'highcharts/modules/export-data';
import { UtcToLocalPipe } from '../../../utils/utc-to-local.pipe';
import { TemperatureOutputPipe } from '../../../utils/temperature-output.pipe';
import { DatePipe } from '@angular/common';
import { ChartHelper } from '../../dashboard-utils/chart-helper';
import { ChartExport } from '../../dashboard-utils/chart-export';
export class ChartComponent {
    constructor(apiService) {
        this.apiService = apiService;
        this.removeSelfEvenet = new EventEmitter();
        this.changedLimitEvent = new EventEmitter();
        this.pdfOutputEvent = new EventEmitter();
        this.thereIsData = true;
        this.failedToLoad = false;
        /** Number of days we want to look back on */
        this.dataRangInDays = 14;
        // Log
        this.temperatureLog = '';
        this.humidityLog = '';
        this.dewLog = '';
        this.surfTemperatureLog = '';
        this.maxDiffDewSurfC = 3;
        this.maxDiffDewSurfF = 5;
        this.UTCtoLocal = new UtcToLocalPipe();
        this.datePipe = new DatePipe('en_US');
        this.globalZoomMax = 0;
        this.globalZoomMin = 0;
        // Start limit values.
        this.startMax = 0;
        this.startMin = 0;
        // Colors for the series. First 6 colors are specified by hempel. The rest is highchart deafult colours.
        // the first 3 colors are hepels darker colors and the next three are hempels lighter colors
        this.colors = ['#009fdf', '#64a70b', '#0033a0', '#2c5234', '#cf4520', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce',
            '#492970', '#f28f43', '#77a1e5', '#c42525', '#a6c96a'];
        /**
         * Used as a lock for the notification function.
         * If this value has changed within the 200 millisec pause between call and execution of the notification log calculator,
         * another call has been made.
         */
        this.updateNotificationLogLock = 0;
    }
    //#endregion
    ngOnInit() {
        // Adding export to higfhchart libary
        exporting(HighchartsStock);
        CSVexport(HighchartsStock);
        // disables the exporting buttons from all charts.
        HighchartsStock.setOptions({ exporting: { enabled: false } });
        this.deviceAreaModel$ = this.apiService.getDeviceArea(this.deviceAreaString); // Gets Devicearea Model
        this.deviceAreaModel$.subscribe(a => {
            // sets max and min values
            this.maxTemp = a.maxTemperatur;
            this.minTemp = a.minTemperatur;
            this.maxHum = a.maxHumidity;
            this.minHum = a.minHumidity;
            this.maxSurf = a.maxSurfaceTemperature;
            this.minSurf = a.minSurfaceTemperature;
            this.deviceArea = a;
            // setup the charts
            this.setupCharts();
        }, _ => { this.failedToLoad = true; console.log('API ERROR ', _.toString, _); });
        // Setup Resize event from partent
        this.resizeEvent.subscribe(v => {
            this.reSize();
        });
        // setup updateEvent from parent
        this.updateEvent.subscribe(() => {
            this.updateCharts();
        });
        // Setup create PDF when event triggers
        this.createPdfEvent.subscribe(() => {
            this.createPdf();
        });
    }
    // ParentCals
    reSize() {
        // we need to for the divs to change size before reflowing.
        setTimeout(() => {
            this.chartTemp.reflow();
            this.chartHum.reflow();
            this.chartDew.reflow();
        }, 3);
    }
    updateCharts() {
        // Gets Last week and now
        let now = new Date();
        now = new Date(now.getTime());
        for (let i = 0; i < this.devices.length; i++) {
            let timestring = this.lastUpdateTime[i].toString();
            if (timestring[timestring.length - 1] !== 'Z') {
                timestring += 'Z';
            }
            const newData = this.apiService.getFacilityMonitordata(this.devices[i].id, new Date(timestring), now);
            newData.subscribe(newDataArray => {
                if (newDataArray.length > 0) {
                    this.thereIsData = true;
                    newDataArray.forEach(d => {
                        const time = this.UTCtoLocal.transform(d.timeStamp);
                        time.setSeconds(0); // Removes secounds and milisecounds
                        time.setMilliseconds(0);
                        let temperatur = d.temperature;
                        let surfaceTemperature = d.surfaceTemperature;
                        let dewPoint = ChartHelper.calculateDewPoint(d.humidity, d.temperature);
                        if (this.settings.temperatureFormat !== 'celcius') {
                            temperatur = TemperatureOutputPipe.celciusToFahrenheit(temperatur);
                            dewPoint = TemperatureOutputPipe.celciusToFahrenheit(dewPoint);
                            surfaceTemperature = TemperatureOutputPipe.celciusToFahrenheit(surfaceTemperature);
                        }
                        this.tempData[i].push([time.getTime(), d.temperature != null ? Math.round(temperatur * 10) / 10 : null]);
                        this.humData[i].push([time.getTime(), d.humidity != null ? Math.round(d.humidity * 10) / 10 : null]);
                        this.dewData[i].push([time.getTime(), d.humidity != null && d.temperature != null ? Math.round(dewPoint * 10) / 10 : null]);
                        this.surfaceData[i].push([time.getTime(), d.surfaceTemperature != null ? Math.round(surfaceTemperature * 10) / 10 : null]);
                    });
                    this.chartTemp.series[i].setData(this.tempData[i], true, false, true);
                    this.chartHum.series[i].setData(this.humData[i], true, false, true);
                    this.chartDew.series[i].setData(this.dewData[i], true, false, true);
                    const dLength = this.devices.length;
                    this.chartDew.series[i + dLength].setData(this.surfaceData[i], true, false, true);
                    this.lastUpdateTime[i] = newDataArray.pop().timeStamp;
                }
            });
        }
    }
    updateTimeSpan() {
        if (this.globalZoomMin + this.globalZoomMax > 100000000) {
            this.chartTemp.xAxis[0].setExtremes(this.globalZoomMin, this.globalZoomMax, true, false);
            this.chartHum.xAxis[0].setExtremes(this.globalZoomMin, this.globalZoomMax, true, false);
            this.chartDew.xAxis[0].setExtremes(this.globalZoomMin, this.globalZoomMax, true, false);
            let min = this.globalZoomMin.toString();
            let max = this.globalZoomMax.toString();
            let calcMaxX = 0;
            this.tempData.forEach(data => {
                if (data.length !== 0) {
                    if (calcMaxX < data[data.length - 1][0]) {
                        calcMaxX = data[data.length - 1][0];
                    }
                }
            });
            // If the max is set to anchor the end we set limit to MAX
            if (this.globalZoomMax === calcMaxX) {
                max = 'max';
                min = (Math.round(this.globalZoomMax - this.globalZoomMin)).toString();
            }
            // Call to parent
            // Splits on -|-
            this.changedLimitEvent.emit(this.deviceAreaString + '-|-' + min + '-|-' + max);
            // UpdatesNotificationLogs
            this.updateNotificationLog();
        }
    }
    updateNotificationLog() {
        const curretnNumber = this.updateNotificationLogLock + 1;
        this.updateNotificationLogLock = curretnNumber;
        setTimeout((min, max) => {
            // If these numbers arent equal that means this call is not the latest call within 200 ms
            if (this.updateNotificationLogLock === curretnNumber) {
                this.updateNotificationLogLock = 0;
                // Instantiate log variables
                this.temperatureLog = '';
                this.humidityLog = '';
                this.dewLog = '';
                this.surfTemperatureLog = '';
                // convert Min and max to numbers
                // Find Violations.
                for (let i = 0; i < this.devices.length; i++) {
                    const sensorName = this.devices[i].device.name;
                    this.temperatureLog += this.checkForThresholdViolation(this.tempData[i], [this.globalZoomMin, this.globalZoomMax], this.maxTemp, this.minTemp, sensorName, 'Ambient Temperature');
                    this.humidityLog += this.checkForThresholdViolation(this.humData[i], [this.globalZoomMin, this.globalZoomMax], this.maxHum, this.minHum, sensorName, 'Relative Humidity');
                    this.surfTemperatureLog += this.checkForThresholdViolation(this.surfaceData[i], [this.globalZoomMin, this.globalZoomMax], this.maxSurf, this.minSurf, sensorName, 'Surface Temperature');
                    this.dewLog += this.checkDewSurfaceThresholdViolation(this.dewData[i], [this.globalZoomMin, this.globalZoomMax], this.surfaceData[i], sensorName);
                }
            }
        }, 200);
    }
    checkDewSurfaceThresholdViolation(dewPointData, zoom, surfaceData, sensorName) {
        let log = '';
        let exceeded = null;
        let maxDiff = this.maxDiffDewSurfC;
        if (this.settings.temperatureFormat !== 'celcius') {
            maxDiff = this.maxDiffDewSurfF;
        }
        for (let i = 0; i < dewPointData.length && i < surfaceData.length; i++) {
            if (surfaceData[i][0] > zoom[0]) {
                if (surfaceData[i][0] > zoom[1]) {
                    break;
                }
                if (surfaceData[i][1] == null || dewPointData[i][1] == null) {
                    continue;
                }
                if (exceeded != null) {
                    if (surfaceData[i][1] > dewPointData[i][1] + maxDiff + 1) {
                        log += this.getLogTextForDewPoint(sensorName, exceeded[0], surfaceData[i][0]);
                        exceeded = null;
                        break;
                    }
                }
                else {
                    if (surfaceData[i][1] < dewPointData[i][1] + maxDiff) {
                        exceeded = surfaceData[i];
                    }
                }
            }
        }
        return log;
    }
    checkForThresholdViolation(sensorData, zoom, max, min, sensorName, type) {
        if (max === min) {
            return '';
        }
        let log = '';
        // Instantiate min/max values
        let over = null; // This value is set to the instance when the data went over max. If not set, the data is under max;
        let under = null; // This value is set to the instance when the data went under min. If not set, the data is over min;
        let hasExceededMax = false;
        let hasExceededMin = false;
        for (const data of sensorData) {
            if (data[0] > zoom[0]) {
                if (data[0] > zoom[1]) {
                    break;
                }
                if (data[1] == null) {
                    continue;
                }
                if (hasExceededMax === false) {
                    if (over != null) {
                        if (data[1] < max - 1) {
                            log += this.getLogText(sensorName, 'max', type, over[0], data[0]);
                            over = null;
                            hasExceededMax = true;
                        }
                    }
                    else { // If over is not set
                        // We check if it's over the min or max.
                        if (data[1] > max) {
                            if (over == null) {
                                over = data;
                            }
                        }
                    }
                }
                if (!hasExceededMin) {
                    if (under != null) {
                        if (data[1] > min + 1) {
                            log += this.getLogText(sensorName, 'min', type, under[0], data[0]);
                            under = null;
                            hasExceededMin = true;
                        }
                    }
                    else { // If over is not set
                        // We check if it's over the min or max.
                        if (data[1] < min) {
                            if (under == null) {
                                under = data;
                            }
                        }
                    }
                }
            }
        }
        if (over != null) {
            log += this.getLogText(sensorName, 'max', type, over[0], zoom[1]);
        }
        if (under != null) {
            log += this.getLogText(sensorName, 'min', type, under[0], zoom[1]);
        }
        return log;
    }
    getLogText(sensorName, maxmin, type, startDateUnix, endDateUnix) {
        /* Used to calculate timespan for when it exceedes. Not used now, but will proprely be used later.
        const startDate = new Date(startDateUnix);
        const endDate = new Date(endDateUnix);
    
        let dateRangeString =  startDate.getDate() + '/'
                                + (startDate.getMonth() + 1) + '  '
                                + startDate.getHours() + ':'
                                + ('00' + startDate.getMinutes()).slice(-2) + ' - ';
    
    
        if (startDate.getDate() !== endDate.getDate() || startDate.getMonth() !== endDate.getMonth()) {
          dateRangeString += endDate.getDate() + '/'
                              + (endDate.getMonth() + 1) + '  '
                              + endDate.getHours() + ':'
                              + ('00' + endDate.getMinutes()).slice(-2);
        } else {
          dateRangeString += endDate.getHours() + ':' + ('00' + endDate.getMinutes()).slice(-2);
        } */
        return 'Sensor ' + sensorName + ' has exceeded ' + maxmin + ' value for ' + type + '.\n';
    }
    getLogTextForDewPoint(sensorName, startDateUnix, endDateUnix) {
        /*   Used to calculate timespan for when it exceedes. Not used now, but will proprely be used later.
        const startDate = new Date(startDateUnix);
        const endDate = new Date(endDateUnix);
    
        let dateRangeString =  startDate.getDate() + '/'
                                + (startDate.getMonth() + 1) + '  '
                                + startDate.getHours() + ':' +
                                ('00' + startDate.getMinutes()).slice(-2) + ' - ';
    
    
        if (startDate.getDate() !== endDate.getDate() || startDate.getMonth() !== endDate.getMonth()) {
          dateRangeString += endDate.getDate() + '/'
                              + (endDate.getMonth() + 1) + '  '
                              + endDate.getHours() + ':'
                              + ('00' + endDate.getMinutes()).slice(-2);
        } else {
          dateRangeString += endDate.getHours() + ':' + ('00' + endDate.getMinutes()).slice(-2);
        } */
        return 'Sensor ' + sensorName + ': Surface temperature has dropped below the Dew Point (+3°C/+5°F).\n';
    }
    // Call to parent
    removeSelfEvent() {
        this.removeSelfEvenet.emit(this.deviceAreaString);
    }
    //#region Setup chart Code
    /** This method is called to setup the charts.
     * The code to setup the charts is quite comprehensive so it have been split into more methods.
     */
    setupCharts() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.getDevices();
            // Loads the data to the charts
            yield this.loadData();
            // Setup the charts series. The series uses the data just loaded.
            this.setupSeries();
            // Setup the charts Yaxis.
            this.setupYaxis();
            // Creats the charts out of the just setup series and axis.
            this.createChart();
        });
    }
    getDevices() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // Gets Last week and now
            const lastWeek = new Date();
            const now = new Date(lastWeek);
            lastWeek.setDate(lastWeek.getDate() - this.dataRangInDays);
            // Gets the number of devices to get datafrom
            this.devices = yield this.apiService.getFacilityMonitorsByDeviceArea(this.deviceArea.areaID, lastWeek, now).toPromise();
            this.lastUpdateTime = new Array(this.devices.length);
        });
    }
    /** Loads data from the api and puts the data into the arrays tempData, humData and dewData
     * These arrays are used to supply the charts with data.
     * Also it's ends with setting the last updated variable to the value of the last downloaded value.
     */
    loadData() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // resets the data arrays
            this.tempData = [];
            this.humData = [];
            this.dewData = [];
            this.surfaceData = [];
            // Gets Last week and now
            const now = new Date();
            const lastWeek = new Date(now);
            lastWeek.setDate(lastWeek.getDate() - this.dataRangInDays);
            let thereIsLoadedData = false;
            // Loops through the devices connected to the area.
            for (let q = 0; q < this.devices.length; q++) {
                // Gets the data from the api
                const data = this.apiService.getFacilityMonitordata(this.devices[q].id, lastWeek, now);
                data.subscribe(d => {
                    if (d.length !== 0) {
                        thereIsLoadedData = true;
                    }
                    let i = -1;
                    // Load temperature data
                    this.tempData.push(Array.from({ length: d.length }, () => {
                        i++;
                        // gets the time for the data
                        const time = this.UTCtoLocal.transform(d[i].timeStamp);
                        time.setSeconds(0);
                        time.setMilliseconds(0);
                        let temperatur = d[i].temperature;
                        if (this.settings.temperatureFormat !== 'celcius') {
                            temperatur = TemperatureOutputPipe.celciusToFahrenheit(temperatur);
                        }
                        return [time.getTime(), d[i].temperature != null ? Math.round(temperatur * 10) / 10 : null];
                    }));
                    // Load humidity data
                    i = -1;
                    this.humData.push(Array.from({ length: d.length }, () => {
                        i++;
                        // gets the time for the data
                        const time = this.UTCtoLocal.transform(d[i].timeStamp);
                        time.setSeconds(0);
                        time.setMilliseconds(0);
                        return [time.getTime(), d[i].humidity != null ? Math.round(d[i].humidity * 10) / 10 : null];
                    }));
                    // Load dew data.
                    i = -1;
                    this.dewData.push(Array.from({ length: d.length }, () => {
                        i++;
                        // gets the time for the data
                        const time = this.UTCtoLocal.transform(d[i].timeStamp);
                        time.setSeconds(0);
                        time.setMilliseconds(0);
                        let dewPoint = ChartHelper.calculateDewPoint(d[i].humidity, d[i].temperature);
                        if (this.settings.temperatureFormat !== 'celcius') {
                            dewPoint = TemperatureOutputPipe.celciusToFahrenheit(dewPoint);
                        }
                        return [time.getTime(), d[i].humidity != null && d[i].temperature != null ? Math.round(dewPoint * 10) / 10 : null];
                    }));
                    // Load Surfacetemperature data
                    i = -1;
                    this.surfaceData.push(Array.from({ length: d.length }, () => {
                        i++;
                        // gets the time for the data
                        const time = this.UTCtoLocal.transform(d[i].timeStamp);
                        time.setSeconds(0);
                        time.setMilliseconds(0);
                        let temperatur = d[i].surfaceTemperature;
                        if (this.settings.temperatureFormat !== 'celcius') {
                            temperatur = TemperatureOutputPipe.celciusToFahrenheit(temperatur);
                        }
                        return [time.getTime(), d[i].surfaceTemperature != null ? Math.round(temperatur * 10) / 10 : null];
                    }));
                    // Set lastUpdateDate
                    if (d.length !== 0) {
                        this.lastUpdateTime[q] = d[d.length - 1].timeStamp;
                    }
                });
                /* Ensures that this method is only finished when the data is downloaded.
                  This may cause a problem because the api might be called 2 times. But i haven't encountered a problem yet.*/
                yield data.toPromise();
            }
            this.thereIsData = thereIsLoadedData;
        });
    }
    /** Setup the charts series. To set up the series it needs the series data. hic can be loaded by loadData.
     *
     * This method uses the ChartHelper class. This has been done to make this class smaller and more readable.
     */
    setupSeries() {
        const series = ChartHelper.setupSeries(this.devices, this.tempData, this.humData, this.dewData, this.surfaceData, this.colors);
        this.tempSeriesOptions = series[0];
        this.humSeriesOptions = series[1];
        this.dewSeriesOptions = series[2];
    }
    /** Setup the charts Yaxis. To put the horizontal min/max lines on the axis it needs the max and min values for the charts.
     *
     * This method uses the ChartHelper class. This has been done to make this class smaller and more readable.
     */
    setupYaxis() {
        const yAxis = ChartHelper.setupYaxis(this.maxTemp, this.minTemp, this.maxHum, this.minHum, this.maxSurf, this.minSurf);
        this.tempYAxis = yAxis[0];
        this.humYAxis = yAxis[1];
        this.dewYAxis = yAxis[2];
    }
    /** This method setup the charts. It uses the values that can be created from the LoadData, SetupSeries and seyupYaxis methods.  */
    createChart() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // Setup the temperature chart
            if (this.chartTemp == null) {
                this.chartTemp = new HighchartsStock.StockChart('chart-temp-' + this.deviceAreaString, this.getChartOptions(this.tempSeriesOptions, this.tempYAxis, 0));
            }
            // Setup the humidity chart
            if (this.chartHum == null) {
                this.chartHum = new HighchartsStock.StockChart('chart-hum-' + this.deviceAreaString, this.getChartOptions(this.humSeriesOptions, this.humYAxis, 1));
            }
            // setup the dew point chart
            if (this.chartDew == null) {
                this.chartDew = new HighchartsStock.StockChart('chart-dew-' + this.deviceAreaString, this.getChartOptions(this.dewSeriesOptions, this.dewYAxis, 2));
            }
            // Sets the global zoom to the first charts zoom.
            // If the input limit is sat then we use that else we set the charts zoom to 4 hours from the first chart.
            if (this.inputLimit !== null && this.inputLimit !== undefined && this.inputLimit !== '') {
                // Gets the values
                const splitLimit = this.inputLimit.split('-');
                if (splitLimit[1] === 'max') { // If the limit is set to be max
                    let max = 0;
                    let min = parseInt(splitLimit[0], 10);
                    this.tempData.forEach(data => {
                        if (data.length !== 0) {
                            if (max < data[data.length - 1][0]) {
                                max = data[data.length - 1][0];
                            }
                        }
                    });
                    if (min > 0) {
                        min = max - min;
                    }
                    else {
                        min = max - (4 * 3600 * 1000);
                    }
                    this.startMin = min;
                    this.startMax = max;
                    this.globalZoomMin = min;
                    this.globalZoomMax = max;
                }
                else {
                    this.startMin = parseInt(splitLimit[0], 10);
                    this.startMax = parseInt(splitLimit[1], 10);
                    if (this.startMin > 0 && this.startMax > 0) {
                        this.globalZoomMin = this.startMin;
                        this.globalZoomMax = this.startMax;
                    }
                }
            }
            else {
                let max = 0;
                this.tempData.forEach(data => {
                    if (data.length !== 0) {
                        if (max < data[data.length - 1][0]) {
                            max = data[data.length - 1][0];
                        }
                    }
                });
                this.globalZoomMin = max - (4 * 3600 * 1000);
                this.globalZoomMax = max;
            }
            this.updateTimeSpan();
        });
    }
    /** Generetes Chart options, used to setup the charts.
     * To put the horizontal min/max lines on the axis it needs the max and min values for the charts.
     *
     * This method uses the ChartHelper class. This has been done to make this class smaller and more readable.
     * The ChartHelper class does must of the work,
     * but this method setups the update timespan event that triggers when the extremes changes for the chart.
     */
    getChartOptions(seriesOptions, yaxis, nr) {
        // Gets the options from the helper class
        const options = ChartHelper.getChartOptions(seriesOptions, yaxis, nr, this.magicToken, this.showButtons, this.colors, this.settings);
        options.tooltip.shared = true;
        options.tooltip.split = false;
        options.tooltip.outside = true;
        // Setup the timespan update event. This is not done in the chartHelper because it dosen't know the updateTimespan method in this class.
        options.xAxis.events = {
            afterSetExtremes: (extremeEvent) => {
                const xMin = extremeEvent.min;
                const xMax = extremeEvent.max;
                if (xMin !== this.globalZoomMin || xMax !== this.globalZoomMax) {
                    this.globalZoomMin = xMin;
                    this.globalZoomMax = xMax;
                    this.updateTimeSpan();
                }
            }
        };
        return options;
    }
    //#endregion
    /** Created the values used to create a pdf. The values created are emitted to the parent compoent to create a pdf out of.
     *
     * It used the ChartExport class to generate the chart options used to cusimize the charts.
     */
    createPdf() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // uses chartExport helper class to get pdfchart potions
            const tempSvg = this.chartTemp.getSVG(ChartExport.pdfChartOptions('Ambient Temperature', this.globalZoomMin, this.globalZoomMax, true));
            const humSvg = this.chartHum.getSVG(ChartExport.pdfChartOptions('Relative Humidity', this.globalZoomMin, this.globalZoomMax, false));
            const dewSvg = this.chartDew.getSVG(ChartExport.pdfChartOptions('Dew Point', this.globalZoomMin, this.globalZoomMax, false));
            const output = {
                area: this.deviceArea,
                charts: [tempSvg, humSvg, dewSvg],
                log: this.temperatureLog + this.humidityLog + this.surfTemperatureLog + this.dewLog
            };
            this.pdfOutputEvent.emit(output);
        });
    }
    /** Used to download a CSV file of the areas chart values. It uses the ChartExport class. */
    downloadCSV() {
        // Opens CSV info modal
        document.getElementById('openCsvModalButton').click();
        // Downloads the CSV file
        ChartExport.downloadCSV(this.tempSeriesOptions, this.humSeriesOptions, this.dewSeriesOptions, this.deviceAreaString, this.deviceArea.areaName);
    }
}
