import { Observable, of } from 'rxjs';
import { catchError, map, share } from 'rxjs/operators';
import { PhotoEvidenceEndpointService, JobEndpointService, FacilityMonitoringEndpointService, ProbeEndpointService, DeviceAreaEndpointService, DeviceEndpointService, PaintSystemEndpointService, CustomerEndpointService, MagicLinkTokenEndpointService } from './api/api';
import { DeviceArea, ReportData, Probe, FacilityMonitor, Job, JobOverview, LayerActuals, LayerActualsUpdate, MagicLinkToken, PhotoEvidence, Device, DeviceData } from '.';
import * as i0 from "@angular/core";
import * as i1 from "./api/jobEndpoint.service";
import * as i2 from "./api/photoEvidenceEndpoint.service";
import * as i3 from "./api/customerEndpoint.service";
import * as i4 from "./api/paintSystemEndpoint.service";
import * as i5 from "./api/deviceEndpoint.service";
import * as i6 from "./api/deviceAreaEndpoint.service";
import * as i7 from "./api/probeEndpoint.service";
import * as i8 from "./api/facilityMonitoringEndpoint.service";
import * as i9 from "./api/magicLinkTokenEndpoint.service";
export class BackendApiService {
    constructor(jobService, photoEvidenceService, customerService, paintSystemService, deviceService, deviceAreaSevice, probeService, facilityMonitorService, magicLinkTokenService) {
        this.jobService = jobService;
        this.photoEvidenceService = photoEvidenceService;
        this.customerService = customerService;
        this.paintSystemService = paintSystemService;
        this.deviceService = deviceService;
        this.deviceAreaSevice = deviceAreaSevice;
        this.probeService = probeService;
        this.facilityMonitorService = facilityMonitorService;
        this.magicLinkTokenService = magicLinkTokenService;
    }
    createPhotoEvidence(photo) {
        return this.photoEvidenceService.createPhotoEvidence(photo).pipe(share());
    }
    getPhotoEvidences(id) {
        return this.photoEvidenceService.getPhotoEvidences(id).pipe(share());
    }
    updatePhotoEvidenceTag(jobId, photoId, photoEvidence) {
        return this.photoEvidenceService.updatePhotoEvidenceTag(jobId, photoId, photoEvidence).pipe(share());
    }
    deletePhotoEvidence(jobId, photoId) {
        return this.photoEvidenceService.deletePhotoEvidence(jobId, photoId).pipe(share());
    }
    addCustomer(name) {
        return this.customerService.createCustomer({ name }).pipe(share(), map(value => value.map(x => x.name)));
    }
    addPaintSystems(name) {
        return this.paintSystemService.createPaintSystem({ name }).pipe(share(), map(value => value.map(x => x.name)));
    }
    createJob(batch) {
        return this.jobService.createJob(batch).pipe(share());
    }
    updateJob(oldId, batch) {
        return this.jobService.updateJob(oldId, batch).pipe(catchError(error => {
            if (error.error instanceof ErrorEvent) {
                console.log(`Error: ${error.error.message}`);
            }
            else {
                console.log(`Error: ${error.message}`);
            }
            return of(undefined);
        }), share());
    }
    getJob(id) {
        return this.jobService.getJob(id).pipe(share());
    }
    getJobs() {
        return this.jobService.getJobs().pipe(share());
    }
    getCustomers() {
        return this.customerService.getCustomers().pipe(share(), map(value => value.map(x => x.name)));
    }
    getMyDevices() {
        return this.deviceService.getDevices().pipe(share());
    }
    getMyHistoricalDevices() {
        return this.deviceService.getHistoricalDevices().pipe(share());
    }
    getFacilityMonitoringDevices() {
        return this.deviceService.getFacilityMonitoringDevices().pipe(share());
    }
    updateDevice(device) {
        return this.deviceService.updateDevice(device).pipe(share());
    }
    getSpecificDevice(id) {
        return this.deviceService.getDevice(id).pipe(share());
    }
    getDeviceAreas() {
        return this.deviceAreaSevice.getDeviceAreas().pipe(share());
    }
    getDeviceArea(id) {
        return this.deviceAreaSevice.getDeviceArea(id).pipe(share());
    }
    createDeviceArea(area) {
        return this.deviceAreaSevice.createDeviceArea(area).pipe(share());
    }
    updateDeviceArea(id, area) {
        return this.deviceAreaSevice.updateDeviceArea(id, area).pipe(share());
    }
    getPaintSystems() {
        return this.paintSystemService.getPaintSystem().pipe(share(), map(value => value.map(x => x.name)));
    }
    getReport(id) {
        return this.jobService.getReport(id).pipe(share());
    }
    layerAction(id) {
        return this.jobService.layerAction(id).pipe(catchError(error => {
            if (error.error instanceof ErrorEvent) {
                console.log(`Error: ${error.error.message}`);
            }
            else {
                console.log(`Error: ${error.message}`);
            }
            return of(undefined);
        }), share());
    }
    layerActionUndo(id) {
        return this.jobService.layerActionUndo(id).pipe(catchError(error => {
            if (error.error instanceof ErrorEvent) {
                console.log(`Error: ${error.error.message}`);
            }
            else {
                console.log(`Error: ${error.message}`);
            }
            return of(undefined);
        }), share());
    }
    addProbe(probe) {
        return this.probeService.createProbe(probe).pipe(share());
    }
    deleteProbe(serialNumber) {
        return this.probeService.deleteProbe(serialNumber).pipe(share());
    }
    deleteJob(id) {
        return this.jobService.deleteJob(id).pipe(share());
    }
    getProbes() {
        return this.probeService.getProbe().pipe(share());
    }
    updateActuals(id, update) {
        return this.jobService.updateActuals(id, update).pipe(share());
    }
    createFacilityMonitor(monitor) {
        return this.facilityMonitorService.createFacilityMonitor(monitor);
    }
    deleteFacilityMonitor(id) {
        return this.facilityMonitorService.deleteFacilityMonitor(id);
    }
    getFacilityMonitor(id) {
        return this.facilityMonitorService.getFacilityMonitor(id);
    }
    getFacilityMonitors() {
        return this.facilityMonitorService.getFacilityMonitors();
    }
    updateFacilityMonitor(id, monitor) {
        return this.facilityMonitorService.updateFacilityMonitor(id, monitor);
    }
    getFacilityMonitorByDevices(id) {
        return this.deviceService.getFacilityMonitorByDevices(id);
    }
    getFacilityMonitorsByDeviceArea(id, startUpdateTime, endUpdateTime) {
        return this.facilityMonitorService.getFacilityMonitorsByDeviceArea(id, startUpdateTime, endUpdateTime).pipe(share());
    }
    getFacilityMonitordata(id, startUpdateTime, endUpdateTime) {
        return this.facilityMonitorService.getFacilityMonitordata(id, startUpdateTime, endUpdateTime).pipe(share());
    }
    deleteDeviceArea(id) {
        return this.deviceAreaSevice.deleteDeviceArea(id).pipe(share());
    }
    createMagicLink() {
        return this.magicLinkTokenService.createMagicLink();
    }
}
BackendApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BackendApiService_Factory() { return new BackendApiService(i0.ɵɵinject(i1.JobEndpointService), i0.ɵɵinject(i2.PhotoEvidenceEndpointService), i0.ɵɵinject(i3.CustomerEndpointService), i0.ɵɵinject(i4.PaintSystemEndpointService), i0.ɵɵinject(i5.DeviceEndpointService), i0.ɵɵinject(i6.DeviceAreaEndpointService), i0.ɵɵinject(i7.ProbeEndpointService), i0.ɵɵinject(i8.FacilityMonitoringEndpointService), i0.ɵɵinject(i9.MagicLinkTokenEndpointService)); }, token: BackendApiService, providedIn: "root" });
