import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { JobOverview, Device, DeviceArea } from '../api';
import { Formatter } from '../utils/formatters';
import { defaultApplicatorSettings } from '../utils/default-applicator-settings';
import { v4 as uuidv4 } from 'uuid';
export class OverviewComponent {
    constructor(apiService, router, authService, settingEndpoint) {
        this.apiService = apiService;
        this.router = router;
        this.authService = authService;
        this.settingEndpoint = settingEndpoint;
        this.devicesFacilityMonitoringLoading = true;
        this.devicesLoading = true;
        this.deviceareaLoading = true;
        this.settings = defaultApplicatorSettings;
        this.dateFormat = 'short';
    }
    init() {
        this.settingEndpoint.getSetting().subscribe(res => {
            this.settings = res;
            this.dateFormat = Formatter.dateString(this.settings);
        });
        this.devices$ = this.apiService.getMyDevices();
        this.devices$.subscribe(_ => {
            this.devicesFacilityMonitoringLoading = false;
        }, _ => {
            this.devicesFacilityMonitoringLoadingError = 'FAILED_LOAD_DEVICES';
        });
        this.devicesFacilityMonitoring$ = this.apiService.getFacilityMonitoringDevices();
        this.devicesFacilityMonitoring$.subscribe(_ => {
            this.devicesLoading = false;
        }, _ => {
            this.devicesLoadingError = 'FAILED_LOAD_DEVICES';
        });
        this.deviceareas$ = this.apiService.getDeviceAreas();
        this.deviceareas$.subscribe(_ => {
            this.deviceareaLoading = false;
            console.log(_);
        }, _ => {
            this.deviceareasLoadingError = 'FAILED_LOAD_DEVICE';
        });
        this.batches$ = this.apiService.getJobs().pipe(map(value => {
            const sorted = value.sort((a, b) => (new Date(b.created).getTime()) - (new Date(a.created).getTime()));
            return sorted.slice(0, 5);
        }));
        this.batches$.subscribe(_ => { }, _ => {
            this.batchesLoadingError = 'FAILED_LOAD_BATCHES';
        });
    }
    ngOnInit() {
        // Silent login did not have the expected result - make new fix
        // this.authService.loginSilent().finally(() => {
        //  this.init();
        // });
        this.init();
    }
    addDeviceArea(inputDeviceAreaName) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const tempArea = {
                areaID: uuidv4(),
                areaName: inputDeviceAreaName,
                maxTemperatur: 0,
                minTemperatur: 0,
                temperatureNotificationActive: false,
                maxHumidity: 0,
                minHumidity: 0,
                humidityNotificationActive: false,
                maxSurfaceTemperature: 0,
                minSurfaceTemperature: 0,
                surfaceTemperatureNotificationActive: false,
                dewSurfTempNotificationActive: false
            };
            const h = this.apiService.createDeviceArea(tempArea);
            yield h.toPromise();
            window.location.reload();
        });
    }
}
