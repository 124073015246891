import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import 'rxjs/add/operator/filter';
import { MagicApiService } from '../../api/magic-api.service';
import { Subject } from 'rxjs-compat/Subject';
import { ApplicatorSetting } from '../../api';
import { defaultApplicatorSettings } from '../../utils/default-applicator-settings';
// PDF imports
import * as pdfMake from 'pdfmake/build/pdfmake.js';
import { getPdfFromInput } from '../dashboard-report/dashboard-report';
import { images } from '../../export-to-pdf/template/images';
export class DashboardComponent {
    constructor(apiService, localeId, httpClinet, router, authService, settingEndpoint, route, location, deviceEndpoint, deviceAreaEndpoint, facilityEndpoint, logoFetcher, settingService) {
        this.apiService = apiService;
        this.localeId = localeId;
        this.httpClinet = httpClinet;
        this.router = router;
        this.authService = authService;
        this.settingEndpoint = settingEndpoint;
        this.route = route;
        this.location = location;
        this.deviceEndpoint = deviceEndpoint;
        this.deviceAreaEndpoint = deviceAreaEndpoint;
        this.facilityEndpoint = facilityEndpoint;
        this.logoFetcher = logoFetcher;
        this.settingService = settingService;
        this.showButtons = true;
        this.simpleView = false;
        // Children events
        this.resizeEvent = new Subject();
        this.updateEvent = new Subject();
        /** The created magic link. This is used to store the just created magicLink. */
        this.magicLink = '';
        /** How many milisecound there should be between each data update. */
        this.milisecBetweenUpdates = 60000; // 1 min
        // PDF variables
        this.createPdfEvent = new Subject();
        this.settings = defaultApplicatorSettings;
    }
    init() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.selectedArea = null;
            yield this.settingService.getSetting().subscribe(s => {
                this.settings = s;
            }, _ => {
                this.settings = defaultApplicatorSettings;
            });
            this.areaComponentsId = new Array();
            this.route.queryParams.subscribe(params => {
                console.log(params);
                if (params.simpleview !== undefined) {
                    this.showButtons = params.simpleview === 'true' ? false : true;
                    this.simpleView = params.simpleview === 'true' ? true : false;
                }
                if (params.simpleView !== undefined) {
                    this.showButtons = params.simpleView === 'true' ? false : true;
                    this.simpleView = params.simpleView === 'true' ? true : false;
                }
            });
            this.route.queryParams
                .filter(params => params.area)
                .subscribe(params => {
                const tempArea = params.area;
                const token = params.token;
                const limit = params.limit;
                const TFormat = params.F;
                if (TFormat !== null && TFormat !== undefined) {
                    this.settings.temperatureFormat = TFormat;
                }
                // tests if there are an objects from the query
                if (tempArea !== null && tempArea !== undefined) {
                    if (typeof tempArea === 'string') { // tests if there are only one area from the query and adds that to the array.
                        if (tempArea !== '') {
                            this.areaComponentsId.push(tempArea);
                            this.areaLimits = new Array(1);
                        }
                    }
                    else { // tests if there are more areas from the query and sets that array as oure area array.
                        // Removes zero values areas. *These can occure if an area was deleted.
                        const indexOfNull = tempArea.findIndex(x => x === '');
                        if (indexOfNull !== -1) {
                            tempArea.splice(indexOfNull, 1);
                        }
                        this.areaComponentsId = tempArea;
                        this.areaLimits = new Array(tempArea.length);
                    }
                }
                // If there are limit values in the url
                if (limit !== null && limit !== undefined) {
                    if (typeof limit === 'string') { // tests if there are only one area from the query and adds that to the array.
                        this.areaLimits = [limit];
                    }
                    else { // tests if there are more areas from the query and sets that array as oure area array.
                        this.areaLimits = limit;
                    }
                }
                // Test if there are an token. If so then this is a magic link, and the magic api should be used.
                if (token !== null && token !== undefined) {
                    this.showButtons = false;
                    this.magicToken = token;
                    this.apiService = new MagicApiService(this.deviceEndpoint, this.deviceAreaEndpoint, this.facilityEndpoint, token);
                }
            });
            // Gets all devices
            this.areas$ = this.apiService.getDeviceAreas();
            this.areas$.subscribe(x => {
                this.areas = []; // Clears array
                x.forEach(element => {
                    if (!this.areaComponentsId.includes(element.areaID)) {
                        this.areas.push(element);
                    }
                });
                if (this.areas.length > 0) {
                    this.selectedArea = this.areas[0];
                }
            });
            // this.updateTimer();
            setTimeout(() => {
                this.updateTimer();
            }, 10000);
        });
    }
    ngOnInit() {
        this.init();
    }
    resizeChildren() {
        this.resizeEvent.next(true);
    }
    updateTimer() {
        this.updateEvent.next(true);
        if (this.router.url.includes('/facilitymonitoring/view')) {
            setTimeout(() => {
                this.updateTimer();
            }, this.milisecBetweenUpdates);
        }
    }
    // Updates the limit value for each area.
    updateLimit(value) {
        // Values are split on -|-
        const splittedValues = value.split('-|-');
        const id = splittedValues[0];
        const min = splittedValues[1];
        const max = splittedValues[2];
        // Finds the area which raised the event
        for (let i = 0; i < this.areaComponentsId.length; i++) {
            if (this.areaComponentsId[i] === id) {
                this.areaLimits[i] = min + '-' + max;
                break;
            }
        }
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
                area: this.areaComponentsId
            },
            queryParamsHandling: 'merge'
        });
    }
    remove(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.areaComponentsId.includes(id)) {
                // If there is
                if (this.areaComponentsId.length === 1) {
                    this.areaLimits = [];
                    this.areaComponentsId = [];
                }
                else {
                    this.areaLimits.splice(this.areaComponentsId.indexOf(id), 1);
                    this.areaComponentsId.splice(this.areaComponentsId.indexOf(id), 1);
                }
                // Nogle gang så virker dette ikke :? for some reason. Ser ud til at det er hver anden gang man trykker.
                this.router.navigate([], {
                    relativeTo: this.route,
                    queryParams: {
                        // Adds a zero value to the query. If we don't do this the query dosen't update when we remove an area. apparently
                        area: this.areaComponentsId.concat(''),
                        limit: this.areaLimits
                    },
                    replaceUrl: true,
                    preserveQueryParams: false,
                    skipLocationChange: false,
                    preserveFragment: false,
                    queryParamsHandling: ''
                });
                this.resizeChildren();
                // Tilføjer areaet til listen så den kan tilføjes .
                const p = this.apiService.getDeviceArea(id).toPromise();
                const deletedDeviceArea = yield p;
                this.areas.push(deletedDeviceArea);
                if (this.areas.length === 1) {
                    this.selectedArea = this.areas[0];
                }
            }
        });
    }
    addAreaFunc() {
        if (this.areas.includes(this.selectedArea)) {
            // Adds a value to areaLimits
            this.areaLimits.push('');
            // Adds area to query
            this.router.navigate([], {
                relativeTo: this.route,
                queryParams: {
                    area: this.areaComponentsId.concat([this.selectedArea.areaID]),
                    limit: this.areaLimits
                },
                queryParamsHandling: 'merge'
            });
            // removes area from selctor
            this.areas.splice(this.areas.indexOf(this.selectedArea), 1);
            // Resizes the children
            this.resizeChildren();
            // set selecotr to none.
            if (this.areas.length > 0) {
                this.selectedArea = this.areas[0];
            }
            else {
                this.selectedArea = null;
            }
        }
        else {
            // IF THE DEVICE COULDN'T BE FOUND. HERE WE SHOULD PUT SOME OUTPUT FOR THE USER
            console.log('SelectedArea could not be found in area list');
        }
    }
    createMagicLink() {
        this.apiService.createMagicLink().subscribe(x => {
            const urlTree = this.router.parseUrl(this.router.url);
            urlTree.queryParams = {
                token: x.token,
                area: this.areaComponentsId,
                limit: this.areaLimits,
                F: this.settings.temperatureFormat
            };
            this.magicLink = window.location.origin + urlTree.toString();
            document.getElementById('openMagicLinkModalButton').click();
        });
    }
    copyInputMessage(inputElement) {
        const inputElem = document.getElementById('magicLinkSpan');
        inputElem.select();
        document.execCommand('copy');
    }
    // Prepares the pdf and opens the title modal.
    preparePDF() {
        // Clears old pdf data and gets new pdf data.
        this.pdfAreas = [];
        this.createPdfEvent.next(true);
        document.getElementById('openPdfTitleModal').click();
    }
    // Generate the PDF
    generatePDF(titleHTMLElement) {
        const title = titleHTMLElement.value;
        if (title === '' || title === undefined || title === null) {
            // If there are not set any title, the user is promted
            document.getElementById('PDF-ErrorMessage').innerHTML = 'Please set an title';
        }
        else {
            // If the data is ready it creates the pdf
            if (this.pdfAreas.length === this.areaComponentsId.length) {
                this.logoFetcher.fetchLogoContent().subscribe(logo => {
                    const pdfDocGenerator = pdfMake.createPdf(getPdfFromInput({
                        title,
                        areas: this.pdfAreas,
                        logo: logo !== '' ? logo : images.hempel,
                    }, this.localeId, this.settings));
                    pdfDocGenerator.download(title);
                    // pdfDocGenerator.open();
                });
            }
            else {
                // If the data is not ready the user is prompted.
                document.getElementById('PDF-ErrorMessage').innerHTML = 'An error occured please try again';
            }
        }
    }
    // Childs way to deliver area data.
    getPdfAreas(TempInputAreaData) {
        this.pdfAreas.push(TempInputAreaData);
    }
}
