export * from './customFieldLabelEndpoint.service';
import { CustomFieldLabelEndpointService } from './customFieldLabelEndpoint.service';
export * from './customerEndpoint.service';
import { CustomerEndpointService } from './customerEndpoint.service';
export * from './deviceAreaEndpoint.service';
import { DeviceAreaEndpointService } from './deviceAreaEndpoint.service';
export * from './deviceEndpoint.service';
import { DeviceEndpointService } from './deviceEndpoint.service';
export * from './facilityMonitoringEndpoint.service';
import { FacilityMonitoringEndpointService } from './facilityMonitoringEndpoint.service';
export * from './jobEndpoint.service';
import { JobEndpointService } from './jobEndpoint.service';
export * from './magicLinkTokenEndpoint.service';
import { MagicLinkTokenEndpointService } from './magicLinkTokenEndpoint.service';
export * from './paintSystemEndpoint.service';
import { PaintSystemEndpointService } from './paintSystemEndpoint.service';
export * from './photoEvidenceEndpoint.service';
import { PhotoEvidenceEndpointService } from './photoEvidenceEndpoint.service';
export * from './probeEndpoint.service';
import { ProbeEndpointService } from './probeEndpoint.service';
export * from './settingsEndpoint.service';
import { SettingsEndpointService } from './settingsEndpoint.service';
export * from './templateEndpoint.service';
import { TemplateEndpointService } from './templateEndpoint.service';
export const APIS = [CustomFieldLabelEndpointService, CustomerEndpointService, DeviceAreaEndpointService, DeviceEndpointService, FacilityMonitoringEndpointService, JobEndpointService, MagicLinkTokenEndpointService, PaintSystemEndpointService, PhotoEvidenceEndpointService, ProbeEndpointService, SettingsEndpointService, TemplateEndpointService];
