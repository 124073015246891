import * as tslib_1 from "tslib";
import { OnInit, ElementRef } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicatorSetting } from '../../api';
import { v4 as uuidv4 } from 'uuid';
import { FormControl, FormGroup, Validators } from '@angular/forms';
export class DeviceEditorComponent {
    constructor(apiService) {
        this.apiService = apiService;
        this.numberRegEx = '^-?[0-9]\\d*$';
        this.deviceForm = new FormGroup({
            name: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(13)]),
            interval: new FormControl(10, [Validators.required, Validators.minLength(1)]),
            serialNumber: new FormControl(),
            isFacilityMonitor: new FormControl(),
            deviceArea: new FormControl(Validators.required),
            maxTemp: new FormControl(0, [Validators.required, Validators.minLength(1), Validators.pattern(this.numberRegEx)]),
            minTemp: new FormControl(0, [Validators.required, Validators.minLength(1), Validators.pattern(this.numberRegEx)]),
            tempNotificationActive: new FormControl(false),
            maxHum: new FormControl(0, [Validators.required, Validators.minLength(1), Validators.pattern(this.numberRegEx)]),
            minHum: new FormControl(0, [Validators.required, Validators.minLength(1), Validators.pattern(this.numberRegEx)]),
            humNotificationActive: new FormControl(false),
            maxSurfTemp: new FormControl(0, [Validators.required, Validators.minLength(1), Validators.pattern(this.numberRegEx)]),
            minSurfTemp: new FormControl(0, [Validators.required, Validators.minLength(1), Validators.pattern(this.numberRegEx)]),
            surfTempNotificationActive: new FormControl(false),
            dewSurfNotificationActive: new FormControl(false),
            note: new FormControl('')
        });
        this.creating = false;
        this.inputError = false;
        this.deviceActive = false;
        this.LoadingSelectedArea = false;
        this.ChangeDeviceArea = () => {
            const inputArea = this.deviceForm.get('deviceArea').value;
            this.deviceForm.patchValue({
                maxTemp: '' + inputArea.maxTemperatur,
                minTemp: '' + inputArea.minTemperatur,
                maxHum: '' + inputArea.maxHumidity,
                minHum: '' + inputArea.minHumidity,
                maxSurfTemp: '' + inputArea.maxSurfaceTemperature,
                minSurfTemp: '' + inputArea.minSurfaceTemperature,
                humNotificationActive: inputArea.humidityNotificationActive,
                tempNotificationActive: inputArea.temperatureNotificationActive,
                surfTempNotificationActive: inputArea.surfaceTemperatureNotificationActive,
                dewSurfNotificationActive: inputArea.dewSurfTempNotificationActive
            });
        };
        this.addDeviceArea = (inputDeviceAreaName) => {
            const tempArea = {
                areaID: uuidv4(),
                areaName: inputDeviceAreaName,
                maxTemperatur: 0,
                minTemperatur: 0,
                temperatureNotificationActive: false,
                maxHumidity: 0,
                minHumidity: 0,
                humidityNotificationActive: false,
                minSurfaceTemperature: 0,
                maxSurfaceTemperature: 0,
                surfaceTemperatureNotificationActive: false,
                dewSurfTempNotificationActive: false
            };
            this.apiService.createDeviceArea(tempArea).subscribe(x => {
                tempArea.areaID = x;
                this.LoadedDeviceAreas.push(tempArea);
                this.deviceAreas$ = new Observable(o => { o.next(this.LoadedDeviceAreas); });
            });
        };
    }
    get maxHum() { return this.deviceForm.get('maxHum'); }
    get minHum() { return this.deviceForm.get('minHum'); }
    get humNotificationActive() { return this.deviceForm.get('humNotificationActive'); }
    get maxTemp() { return this.deviceForm.get('maxTemp'); }
    get minTemp() { return this.deviceForm.get('minTemp'); }
    get tempNotificationActive() { return this.deviceForm.get('tempNotificationActive'); }
    get maxSurfTemp() { return this.deviceForm.get('maxSurfTemp'); }
    get minSurfTemp() { return this.deviceForm.get('minSurfTemp'); }
    get surfTempNotificationActive() { return this.deviceForm.get('surfTempNotificationActive'); }
    get dewSurfNotificationActive() { return this.deviceForm.get('dewSurfNotificationActive'); }
    get name() { return this.deviceForm.get('name'); }
    ngOnInit() {
        this.deviceAreas$ = this.apiService.getDeviceAreas();
        let intervalMin;
        this.deviceActive = this.deviceModel.state === 0;
        // Setup for interval
        this.Frequencies = ['10', '15', '20', '25', '30', '60'];
        if (this.deviceModel.desiredFrequency != null) {
            const desiredFrequencySplit = this.deviceModel.desiredFrequency.split(':');
            intervalMin = (parseInt(desiredFrequencySplit[0], 10) * 60 + parseInt(desiredFrequencySplit[1], 10)).toString();
            this.StartMins = intervalMin;
        }
        // Init for the form
        this.deviceForm.setValue({
            name: this.deviceModel.name,
            serialNumber: this.deviceModel.serialNumber,
            interval: intervalMin,
            isFacilityMonitor: this.deviceModel.deviceMonitoringType === 1,
            deviceArea: null,
            maxTemp: '0',
            minTemp: '0',
            tempNotificationActive: false,
            maxHum: '0',
            minHum: '0',
            humNotificationActive: false,
            maxSurfTemp: '0',
            minSurfTemp: '0',
            surfTempNotificationActive: false,
            dewSurfNotificationActive: false,
            note: ''
        });
        this.deviceAreas$ = this.apiService.getDeviceAreas();
        this.deviceAreas$.subscribe(d => {
            this.LoadedDeviceAreas = d;
            // setup for facility monitor
            if (this.deviceModel.deviceMonitoringType === 1) {
                this.apiService.getFacilityMonitorByDevices(this.deviceModel.deviceId).subscribe(x => {
                    if (x !== undefined && x !== null) {
                        this.LoadingSelectedArea = false;
                        this.facilitymonitor = x;
                        this.startsFacilityMonitorID = x.id;
                        // findes the newly downloaded devceArea from the devicearea list
                        let tempDeviceArea = d.find(o => o.areaID === x.deviceArea.areaID);
                        if (tempDeviceArea === undefined) {
                            tempDeviceArea = x.deviceArea;
                        }
                        // Patches the formvalue wiht the new values. Must make the values to string so they can be validated correct.
                        this.deviceForm.patchValue({
                            deviceArea: tempDeviceArea,
                            maxTemp: tempDeviceArea.maxTemperatur.toString(),
                            minTemp: tempDeviceArea.minTemperatur.toString(),
                            tempNotificationActive: tempDeviceArea.temperatureNotificationActive,
                            maxHum: tempDeviceArea.maxHumidity.toString(),
                            minHum: tempDeviceArea.minHumidity.toString(),
                            humNotificationActive: tempDeviceArea.humidityNotificationActive,
                            maxSurfTemp: tempDeviceArea.maxSurfaceTemperature.toString(),
                            minSurfTemp: tempDeviceArea.minSurfaceTemperature.toString(),
                            surfTempNotificationActive: tempDeviceArea.surfaceTemperatureNotificationActive,
                            dewSurfNotificationActive: tempDeviceArea.dewSurfTempNotificationActive,
                            note: x.note
                        });
                    }
                });
            }
            else {
                this.LoadingSelectedArea = false;
            }
        });
    }
    FormValidationErrors() {
        let foundErrors = false;
        Object.keys(this.deviceForm.controls).forEach(key => {
            const controlErrors = this.deviceForm.get(key).errors;
            if (controlErrors != null) {
                foundErrors = true;
            }
        });
        return foundErrors;
    }
    upDateModelValues() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let result = true;
            // If there are validations errors return false
            if (this.FormValidationErrors()) {
                return false;
            }
            // Get values to make validation easier.
            const maxTemp = this.deviceForm.get('maxTemp').value;
            const minTemp = this.deviceForm.get('minTemp').value;
            const maxHum = this.deviceForm.get('maxHum').value;
            const minHum = this.deviceForm.get('minHum').value;
            const maxSurfTemp = this.deviceForm.get('maxSurfTemp').value;
            const minSurfTemp = this.deviceForm.get('minSurfTemp').value;
            const humNotificationActive = this.deviceForm.get('humNotificationActive').value;
            const tempNotificationActive = this.deviceForm.get('tempNotificationActive').value;
            const surfTempNotificationActive = this.deviceForm.get('surfTempNotificationActive').value;
            const dewSurfNotificationActive = this.deviceForm.get('dewSurfNotificationActive').value;
            let mins = parseInt(this.deviceForm.get('interval').value, 10);
            const hours = Math.floor(mins / 60);
            mins -= hours * 60;
            this.deviceModel.desiredFrequency = hours + ':' + mins + ':' + '00.0000000';
            this.deviceModel.name = this.deviceForm.get('name').value;
            const isFacilityMonitor = this.deviceForm.get('isFacilityMonitor').value;
            this.deviceModel.deviceMonitoringType = isFacilityMonitor ? 1 : 0;
            if (!isFacilityMonitor) {
                if (this.facilitymonitor !== undefined && this.facilitymonitor !== null) {
                    const h = this.apiService.deleteFacilityMonitor(this.facilitymonitor.id);
                    yield h.toPromise();
                }
            }
            else if (isFacilityMonitor) {
                // Loads devicearea into an variable to make validation easier
                const tempArea = this.deviceForm.get('deviceArea').value;
                if (maxTemp === null || maxTemp === ''
                    || minTemp === null || minTemp === ''
                    || maxHum === null || maxHum === ''
                    || minHum === null || minHum === ''
                    || maxSurfTemp === null || maxSurfTemp === ''
                    || minSurfTemp === null || minSurfTemp === ''
                    || tempArea === null || tempArea === undefined) {
                    result = false;
                }
                else {
                    if (this.facilitymonitor === undefined || this.facilitymonitor === null) {
                        // Creates the Facilitymonitor
                        const id = this.apiService.createFacilityMonitor({
                            id: uuidv4(),
                            device: this.deviceModel,
                            deviceArea: tempArea,
                            startDate: new Date(Date.now()),
                            note: this.deviceForm.get('note').value
                        });
                        id.subscribe(x => {
                            if (x === undefined || x === null) {
                                result = false;
                            }
                        });
                        // IF the area is not the same as the begining, we end the first facilitymonitor and creates a new one.
                    }
                    else if (this.facilitymonitor.deviceArea.areaID !== tempArea.areaID) {
                        const h = this.apiService.deleteFacilityMonitor(this.facilitymonitor.id);
                        yield h.toPromise();
                        // Creates the Facilitymonitor
                        const id = this.apiService.createFacilityMonitor({
                            id: uuidv4(),
                            device: this.deviceModel,
                            deviceArea: tempArea,
                            startDate: new Date(Date.now()),
                            note: this.deviceForm.get('note').value
                        });
                        yield id.subscribe(x => {
                            if (x === undefined || x === null) {
                                result = false;
                            }
                        });
                    }
                    else {
                        // Updates the facilitymonitor
                        this.facilitymonitor.note = this.deviceForm.get('note').value;
                        const id = this.apiService.updateFacilityMonitor(this.facilitymonitor.id, this.facilitymonitor);
                        id.subscribe(x => {
                            if (x === undefined || x === null) {
                                result = false;
                            }
                        });
                    }
                    // If there is changes to the area. The area will be updated.
                    if (maxTemp !== tempArea.maxTemp ||
                        minTemp !== tempArea.minTemp ||
                        maxHum !== tempArea.maxHum ||
                        minHum !== tempArea.minHum ||
                        maxSurfTemp !== tempArea.maxSurfTemp ||
                        minSurfTemp !== tempArea.minSurfTemp ||
                        humNotificationActive !== tempArea.humidityNotificationActive ||
                        tempNotificationActive !== tempArea.temperatureNotificationActive ||
                        surfTempNotificationActive !== tempArea.dewSurfTempNotificationActive ||
                        dewSurfNotificationActive !== tempArea.dewSurfNotificationActive) {
                        tempArea.maxTemperatur = maxTemp;
                        tempArea.minTemperatur = minTemp;
                        tempArea.maxHumidity = maxHum;
                        tempArea.minHumidity = minHum;
                        tempArea.maxSurfaceTemperature = maxSurfTemp;
                        tempArea.minSurfaceTemperature = minSurfTemp;
                        tempArea.humidityNotificationActive = humNotificationActive;
                        tempArea.temperatureNotificationActive = tempNotificationActive;
                        tempArea.surfaceTemperatureNotificationActive = surfTempNotificationActive;
                        tempArea.dewSurfTempNotificationActive = dewSurfNotificationActive;
                        const id = this.apiService.updateDeviceArea(tempArea.areaID, tempArea);
                        id.subscribe(x => {
                            if (x === undefined || x === null || x === '') {
                                result = false;
                            }
                        });
                    }
                }
            }
            return result;
        });
    }
    validateInput() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const result = yield this.upDateModelValues();
            this.inputError = !result;
            return result;
        });
    }
}
