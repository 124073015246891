import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
export class DeviceUpdateComponent {
    constructor(route, router, apiService) {
        this.route = route;
        this.router = router;
        this.apiService = apiService;
        this.waitingForRequest = false;
    }
    ngOnInit() {
        this.id = this.route.snapshot.paramMap.get('id');
        this.refreshJob();
    }
    refreshJob() {
        this.device$ = this.apiService.getSpecificDevice(this.id);
    }
    update(device) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (yield this.deviceModify.validateInput()) {
                this.waitingForRequest = true;
                this.errorMsg = '';
                this.apiService.updateDevice(device).subscribe(id => { this.router.navigate(['']); }, _ => {
                    this.waitingForRequest = false;
                    this.errorMsg = 'FAILED_TO_UPDATE';
                });
            }
            else {
                this.waitingForRequest = false;
                this.errorMsg = 'FAILED_TO_UPDATE';
            }
        });
    }
}
