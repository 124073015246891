import { of } from 'rxjs';
import { share } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class BatchMappingApiService {
    constructor(httpClient, BatchMappingApiUrl) {
        this.httpClient = httpClient;
        this.BatchMappingApiUrl = BatchMappingApiUrl;
    }
    GetProdsFromIds(ids) {
        const idsJoined = ids.filter(x => x.length === 9 && /^\d+$/.test(x)).join(',');
        if (!idsJoined) {
            return of([]);
        }
        const result = this.httpClient.get(this.BatchMappingApiUrl + '/api/product/' + idsJoined);
        return result.pipe(share());
    }
}
BatchMappingApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BatchMappingApiService_Factory() { return new BatchMappingApiService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject("BatchMappingApiUrl")); }, token: BatchMappingApiService, providedIn: "root" });
