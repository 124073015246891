import * as tslib_1 from "tslib";
import { DeviceArea } from '../../api';
export class AreaItemComponent {
    constructor(apiService) {
        this.apiService = apiService;
        this.openModal = (area) => {
            this.CurrentArea = area;
            document.getElementById('openDeleteAreaModalButton').click();
        };
    }
    deleteArea() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // DeleteDeviceArea
            if (this.CurrentArea !== undefined && this.CurrentArea !== null && this.CurrentArea.areaID !== '') {
                const h = this.apiService.deleteDeviceArea(this.CurrentArea.areaID);
                yield h.toPromise();
            }
            window.location.reload();
        });
    }
    openNewTab(id) {
        window.open('facilitymonitoring/view?area=' + id);
    }
}
